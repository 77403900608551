import axios from "axios";

export const procurementCreateRfqService = async (params: any) => {
  return await axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Request for Quotation",
    params
  );
};

export const procurementGetRfqService = async (rfqId: any) => {
  return await axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      `/api/resource/Request for Quotation/${rfqId}?fields=[%22*%22]&start=0&limit=10000`
  );
};

export const procurementAddRfqItemService = async (data: any) => {
  // TODO; user management
  return await axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
      `/api/resource/Request for Quotation Item`,
    data
  );
};

export const procurementSaveRfqService = async (payload: any) => {
  return await axios.put(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/resource/Request for Quotation/" +
      payload.name,
    payload.fields
  );
};
