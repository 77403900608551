import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USERID_FETCH_SUCCESS,
  ACTIVE_ORGANIZATION,
} from "../actions/types";
import Cookies from "js-cookie";

const localuser = Cookies.get();
const user = localuser.user_id ? localuser.user_id : null;

const initialState =
  user && user !== "Guest"
    ? {
        isLoggedIn: true,
        user,
        uniqueUserId: null,
        active_org: localStorage.getItem("active_org"),
      }
    : {
        isLoggedIn: false,
        user: null,
        uniqueUserId: null,
        active_org: null,
      };

export const authProcure = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        active_org: payload.active_org,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case USERID_FETCH_SUCCESS:
      return {
        ...state,
        uniqueUserId: payload.uniqueUserId,
      };
    case ACTIVE_ORGANIZATION:
      return {
        ...state,
        active_org: payload.active_org,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};
