import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index";

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export default store;

export {
  setMessage,
  clearMessage,
  setLoading,
  setPageLoading,
  updateOnboardStatus,
} from "./actions/message";
export {
  LOGIN_SUCCESS,
  USERID_FETCH_SUCCESS,
  UPDATE_SIGNUP_STATE,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  API_LOADING,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SUCCESS_MSG,
  ERROR_MSG,
  INFO_MSG,
  PAGE_LOADING,
  UPDATE_ONBOARD_STATUS,
  RFQ_FILE_UPLOAD_BEGIN,
  RFQ_FILE_UPLOAD_SUCCESS,
  RFQ_FILE_UPLOAD_FAILED,
  RFQ_CREATION_BEGIN,
  RFQ_CREATION_SUCCESS,
  RFQ_CREATION_FAILED,
  RFQ_FETCH_BEGIN,
  RFQ_FETCH_SUCCESS,
  RFQ_FETCH_FAILED,
  QUOTE_FETCH_BEGIN,
  QUOTE_FETCH_SUCCESS,
  QUOTE_FETCH_FAILED,
  CUSTOMER_FETCH_SUCCESS,
  ACTIVE_ORGANIZATION,
} from "./actions/types";
export { register, logout, login } from "./actions/auth";
export {
  registerProcure,
  loginProcure,
  logoutProcure,
} from "./actions/authProcurement";
export { SavePart, SaveRFQ, GetQuote } from "./actions/quote";
export { getUserDetails } from "./actions/user";
export { getUserDetailsProcure } from "./actions/userProcurement";
export {
  registerSupplier,
  loginSupplier,
  logoutSupplier,
} from "./actions/authSupplier";

// export {
// getDashboardCount,
// getOpenRFQs,
// getProfilePercent,
// } from "./actions/dashboardActions";
