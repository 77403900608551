import React, { HTMLProps, Ref, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox as Check,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";


import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
  useResizeColumns,
} from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import Checkbox from "./Checkbox";

type Column = {
  Header: string;
  accessor: string;
};

type Data = {
  [key: string]: any;
};

type Props = {
  column?: Column[];
  data?: Data[];
  renderCheckBox?: boolean;
  renderSerialNo?: boolean;
  onRowSelect?: any;
  showSelectedRowCount?: boolean;
  isShowSearch?: boolean;
  headerColor?: string;
  paginationbg?: string;
  showItemsAdded?: boolean;
  zeroScreenComponent?: any;
  borderColor?: any;
  placeholderText?: string;
  isPagination?: boolean;
  dataLength?: number;
  topRightContent?: any;
  isTotal?: any;
  
};
/* 
React Table relies on memoization to determine when state and 
side effects should update or be calculated.
 This means that every option you pass to useTable should be memoized either via React.useMemo (for objects)
 or React.useCallback (for functions).
 */

export const GenericTables: React.FC<Props> = ({
  column,
  data,
  isTotal = false,
  renderCheckBox,
  renderSerialNo,
  onRowSelect,
  showSelectedRowCount,
  isShowSearch,
  headerColor,
  dataLength,
  // tableSize,
  paginationbg,
  showItemsAdded,
  zeroScreenComponent,
  borderColor,
  placeholderText,
  isPagination = true,
  topRightContent,
}) => {
  // const [dataToDelete, setDataToDelete] = React.useState(
  //   React.useMemo(() => data, [])
  // );
  const color = useColorModeValue("#000", "#000");
  const handleNewRowClick = () => {
    // store.push([])
  };
  // avoid re-rendering, use cached data
  // const columns = useMemo(() => column, [column]);

  const {
    getTableProps,
    getTableBodyProps,
    state,
    headerGroups,
    page,
    state: { columnResizing },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    pageCount,
    pageOptions,
    gotoPage,
    selectedFlatRows,
    setPageSize,
    setGlobalFilter,
  } = useTable(
    {
      columns: column,
      data: data,
      initialState: {
        pageSize: dataLength ? dataLength : 10, // Set initial page size to 5
        // Add other initial states if needed
      },
      // initialState: {
      //   sortBy: columns.slice(1).map((column) => ({
      //     id: column.id,
      //     desc: true, // Set this to true for descending order
      //   })),
      // },
    },
    useResizeColumns,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (renderCheckBox) {
        hooks.visibleColumns?.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
            disableSortBy: true,
          },
          ...columns,
        ]);
      } else if (renderSerialNo) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: "No.",
            Cell: ({ row }) => <span>{row.index + 1}</span>,
            disableSortBy: true,
          },
          ...columns,
        ]);
      } else {
        return;
      }
    }
  );
  const { globalFilter, pageIndex, pageSize } = state;
  const rowData = selectedFlatRows?.map((row) => row.original);
  const lastColumnIndex = column ? column.length - 1 : 0;
  const totalSum = useMemo(() => {
    return page.reduce((sum, row) => {
      // Accessing the value of the last column
      const cellValue = row.values ? row.values[column[lastColumnIndex]?.accessor] : 0;
      
      // Ensure the value is a number, default to 0 if it's not
      const value = typeof cellValue === 'number' ? cellValue : parseFloat(cellValue) || 0;
      // console.log(sum+value)
      return sum + value;
    }, 0);
  }, [page, column, lastColumnIndex]);
  const paginationRange = 2; // number of page numbers to display before and after the current page
  const paginationStart =
    pageIndex - paginationRange < 0 ? 0 : pageIndex - paginationRange;
  const paginationEnd =
    pageIndex + paginationRange >= pageCount
      ? pageCount
      : pageIndex + paginationRange + 1;

  const [columnSpan, setColumnSpan] = React.useState<number>(
    column === undefined ? 100 : column?.length + 1
  ); // colspan for zero screens
  // to check which rows has been selected
  React.useEffect(() => {
    if (onRowSelect) {
      onRowSelect(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  //incase if the date is a string use date-fns package
  //  in the columns data pass another object called as Cell : ({value}) => {return format(new Date(value), 'dd/mm/yyyy)}
  return (
    <Box overflow="auto">
      <Flex
        justifyContent={"space-between"}
        alignItems={topRightContent ? "flex-start" : "flex-end"}
        mb="1.5rem"
      >
        {isShowSearch && (
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            placeholderText={placeholderText}
          />
        )}
        <Box>
          {topRightContent}
          {showSelectedRowCount && (
            <Text textStyle="primary.secondaryText">
              {selectedFlatRows.length}&nbsp; Selected out of &nbsp;
              {data?.length}
            </Text>
          )}
          {showItemsAdded &&
            (selectedFlatRows.length > 0 ? (
              <Text textStyle="primary.secondaryText">
                {selectedFlatRows.length}&nbsp; Selected out of &nbsp;
                {data?.length}
              </Text>
            ) : (
              <Text
                textStyle="primary.secondaryText"
                fontWeight="semibold"
                color="black"
              >
                Items added&nbsp; ({data?.length})
              </Text>
            ))}
        </Box>
      </Flex>

      <TableContainer borderRadius="12px 12px 0px 0px">
        <Table
          {...getTableProps}
          bg="rgba(0, 135, 238, 0.1)"
          size={"sm"}
          borderColor={borderColor ? borderColor : "rgba(0, 135, 238, 0.2)"}
        >
          <Thead>
            {headerGroups?.map((headerGroup, headerIndex) => (
              <Tr {...headerGroup.getHeaderGroupProps} key={headerIndex}>
                {headerGroup.headers?.map((column, index) => (
                  <Th
                    bg={headerColor ? headerColor : "rgba(0, 135, 238, 0.1)"}
                    border={
                      borderColor
                        ? `1px solid ${borderColor}`
                        : "1px solid rgba(0, 135, 238, 0.2)"
                    }
                    textTransform="none"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    color="#000"
                    fontFamily="Rubik"
                    fontWeight={"medium"}
                    textAlign={"left"}
                    fontSize="14px"
                    py="0.5em"
                    px="8px"
                    letterSpacing={0}
                  >
                    {/* <Box p ="0.3em">
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    </Box> */}

                    <Box
                      p="0.5em 0.3em"
                      fontFamily="Rubik"
                      fontWeight="semibold"
                      display={"flex"}
                      flexDirection="row"
                      alignItems="center"
                      gap="1rem"
                    >
                      <span>{column.render("Header")}</span>
                      {!column.disableSortBy && ( // Check if sorting is enabled for the column
                        <span
                          onClick={() => column.toggleSortBy()}
                          style={{ cursor: "pointer" }}
                        >
                          {
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <BsCaretDownFill size="13px" />
                              ) : (
                                <BsCaretUpFill size="13px" />
                              )
                            ) : (
                              <BsCaretUpFill size="13px" />
                            ) // Display both up and down arrows when not sorted
                          }
                        </span>
                      )}
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()} bg="#fff">
            {page?.length > 0 ? (
              page.map((row, index) => {
                prepareRow(row, index);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells?.map((cell, i) => (
                      <Td
                        key={i}
                        {...cell.getCellProps()}
                        border={
                          borderColor
                            ? `1px solid ${borderColor}`
                            : "1px solid rgba(0, 135, 238, 0.2)"
                        }
                        paddingX="5px"
                        color={color}
                      >
                        <Text
                          ml="0.4em"
                          p="0.3em"
                          textStyle={"primary.text"}
                          fontWeight="normal"
                          display={"flex"}
                          flexWrap={"wrap"}
                          as="span"
                        >
                          <span>{cell.render("Cell")}</span>
                        </Text>
                      </Td>
                    ))}
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td
                  colSpan={columnSpan}
                  borderRight={
                    borderColor
                      ? `1px solid ${borderColor}`
                      : "1px solid rgba(0, 135, 238, 0.2)"
                  }
                  borderLeft={
                    borderColor
                      ? `1px solid ${borderColor}`
                      : "1px solid rgba(0, 135, 238, 0.2)"
                  }
                >
                  <Flex
                    width="100%"
                    margin="2rem 0"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="#fff"
                    height="100%"
                  >
                    {zeroScreenComponent}
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {isTotal && (
        <Box
          bg="rgba(0, 135, 238, 0.1)"
          border="1px solid rgba(0, 135, 238, 0.2)"
          borderTop="none"
          p="0.5em"
        >
          <Text fontSize={"14px"}  textAlign="right">
            <b>Total Amount:</b> ₹{totalSum}
          </Text>
        </Box>
      )}
      {/* <button onClick={handleNewRowClick}>Add Row</button> */}
      {isPagination === true && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
          my={isTotal ? "3em" : "1em"}
          pos={"relative"}
          position={"static"}
        >
          <Flex>
            <Text
              mr="0.5em"
              mt="0.1em"
              fontSize={"14px"}
              fontFamily="Rubik"
              color="rgba(94, 98, 120, 1)"
              fontWeight="semibold"
            >
              Page {pageIndex + 1} of {pageOptions.length}
            </Text>
            <React.Fragment>
              <Button
                size="xs"
                bg={
                  pageSize === 10
                    ? paginationbg
                      ? paginationbg
                      : "#0087EE"
                    : undefined
                }
                color={pageSize === 10 ? "#fff" : "rgba(94, 98, 120, 1)"}
                _hover={{ opacity: ".8" }}
                onClick={() => setPageSize(10)}
                children="10"
                mr="0.5em"
              />
              <Button
                color={pageSize === 20 ? "#fff" : "rgba(94, 98, 120, 1)"}
                // bg={pageSize === 20 ? "#0087EE" : undefined}
                bg={
                  pageSize === 20
                    ? paginationbg
                      ? paginationbg
                      : "#0087EE"
                    : undefined
                }
                _hover={{ opacity: ".8" }}
                size="xs"
                onClick={() => setPageSize(20)}
                children="20"
                mr="0.5em"
              />
              <Button
                color={pageSize === 50 ? "#fff" : "rgba(94, 98, 120, 1)"}
                // bg={pageSize === 50 ? "#0087EE" : undefined}
                bg={
                  pageSize === 50
                    ? paginationbg
                      ? paginationbg
                      : "#0087EE"
                    : undefined
                }
                _hover={{ opacity: ".8" }}
                size="xs"
                onClick={() => setPageSize(50)}
                children="50"
                mr="0.5em"
              />
            </React.Fragment>
          </Flex>

          <Flex>
            <Button
              variant="ghost"
              color="#7E8299"
              _hover={{ opacity: ".8" }}
              size="xs"
              ml="0.5em"
              children={"<"}
              onClick={() => gotoPage(0)}
              disabled={!previousPage}
            />

            {Array.from({ length: paginationEnd - paginationStart }, (_, i) => {
              const page = paginationStart + i;
              return (
                <Button
                  size="xs"
                  key={i}
                  bg={
                    pageIndex === page
                      ? paginationbg
                        ? paginationbg
                        : "#0087EE"
                      : undefined
                  }
                  color={pageIndex === page ? "#fff" : undefined}
                  _hover={{ opacity: ".8" }}
                  children={page + 1}
                  onClick={() => gotoPage(page)}
                  ml="0.5em"
                />
              );
            })}

            <Button
              size="xs"
              ml="0.5em"
              variant="ghost"
              color="#7E8299"
              _hover={{ opacity: ".8" }}
              children={">"}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!previousPage}
            />
          </Flex>
        </Box>
      )}
    </Box>
  );
};
