import { frappeMethodService } from "@karkhanaui/react";

export const loggedInUser = async () => {
  let user: string = "";
  // TODO; user management
  await frappeMethodService("frappe.auth.get_logged_user", {})
    .then((res) => {
      user = res.data?.message;
    })
    .catch((error) => {
      console.log(error);
    });

  return user;
};
