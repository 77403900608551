import axios from "axios";

export const summaryCountService = async (rfqId: any) => {
  // TODO; user management
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      `
	/api/method/procurement.api.rfq.rfq_count_status?rfq_id=${rfqId}
	`
  );
};
