import axios from "axios";

export const getAllAddresses = () => {
  // TODO; user management
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      '/api/resource/Address?fields=["*"]&limit=0'
  );
};

export const getAddressService = async (address: string) => {
  // TODO; user management
  return (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL + `/api/resource/Address/${address}`
    )
  ).data;
};

export const getAddressTextService = async (address: string) => {
  // TODO; user management
  const _address = (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL + `/api/resource/Address/${address}`
    )
  ).data;

  const _addressObj = _address.data;

  return `${_addressObj.address_line1} ${_addressObj.address_line2 || ""}, ${
    _addressObj.city || ""
  }, ${_addressObj.state}, ${_addressObj.country} ,${
    _addressObj.pincode
  } Mobile Number : ${_addressObj.phone}`;
};

export const deleteAddress = async (name: string) => {
  // TODO; user management
  return await (
    await axios.delete(
      process.env.REACT_APP_AUTH_LIVE_URL + `/api/resource/Address/${name}`
    )
  ).data;
};

export const addAddresses = (data: any) => {
  // TODO; user management
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL + `/api/resource/Address`,
    data
  );
};

export const updateAddress = async (data: any) => {
  // TODO; user management
  return await (
    await axios.put(
      process.env.REACT_APP_AUTH_LIVE_URL +
        `/api/resource/Address/${data.name}`,
      data
    )
  ).data;
};
