import axios from "axios";

export const getCountriesService = async () => {
  // TODO; user management
  return axios.get("https://address.karkhana.live/countries");
};

export const getStatesService = async (countryCode: String) => {
  // TODO; user management
  return axios.get(`https://address.karkhana.live/stateS/${countryCode}`);
};

export const getCityService = async (
  countryCode: String,
  stateCode: String
) => {
  // TODO; user management
  return axios.get(
    `https://address.karkhana.live/cities/${countryCode}/${stateCode}`
  );
};
