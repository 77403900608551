import axios from "axios";

export const getUserDetailsServiceProcure = () => {
  // TODO; user management
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/procurement.api.auth.get_customerdetials"
  );
};

export const updateUserDetailsServiceProcure = (data: any) => {
  // TODO; user management
  return axios.put(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Customer",
    data
  );
};
